import type { ValidationRuleWithoutParams, ValidationRuleWithParams } from '@vuelidate/core';
import * as validators from '@vuelidate/validators';

import { useI18n } from '@/i18n';

const { createI18nMessage } = validators;
const { t } = useI18n();
const withI18nMessage = createI18nMessage({ t });

export const required = withI18nMessage(validators.required);
export const email = withI18nMessage(validators.email);
export const url = withI18nMessage(validators.url);

const _numbers: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return /\d/.test(value);
  },
};
export const numbers = withI18nMessage(_numbers);

const _upperCase: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return /[A-Z]/.test(value);
  },
};
export const upperCase = withI18nMessage(_upperCase);

const _lowerCase: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return /[a-z]/.test(value);
  },
};
export const lowerCase = withI18nMessage(_lowerCase);

const _specials: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return /[#?!@$%^&*-]/.test(value);
  },
};
export const specials = withI18nMessage(_specials);

let _uniqueDetails: string | null = null;
/**
 * Validation rule that checks if a string contains only unique characters.
 *
 * This rule ensures that the input string is not empty and that no character
 * repeats within the string. The validation is performed using a regular
 * expression which detects any repeated characters.
 *
 * @type {ValidationRuleWithoutParams<string>}
 * @property {Function} $validator The validator function that checks if the
 * string contains unique characters.
 * @param {string} value The string to be validated.
 * @returns {boolean} Returns `true` if the string contains only unique
 * characters, otherwise returns `false`.
 *
 * @example
 * unique$.$validator('abcdefg'); // true
 * unique$.$validator('abcadefg'); // false (character 'a' is repeated)
 */
const _unique: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    const match = /(.).*\1/.exec(value);
    if (match) {
      _uniqueDetails = match[1];
      return false;
    }
    _uniqueDetails = null;
    return true;
  },
};
export const unique = withI18nMessage(_unique);

/**
 * Gets the details of the unique validation, specifically the repeated character if any.
 *
 * @returns The repeated character if found, otherwise null.
 */
export const getUniqueDetails = (): string | null => _uniqueDetails;

const _easyCombinations: string[] = [
  '1234',
  '12345',
  '123456',
  '1234567',
  '12345678',
  '123456789',
  '1234567890',
  'qwer',
  'qwert',
  'qwerty',
  'qwertyu',
  'qwertyui',
  'qwertyuio',
  'qwertyuiop',
  'asdf',
  'asdfg',
  'asdfgh',
  'asdfghj',
  'asdfghjk',
  'asdfghjkl',
  'zxcv',
  'zxcvb',
  'zxcvbn',
  'zxcvbnm',
  'йцук',
  'йцуке',
  'йцукен',
  'йцукенг',
  'йцукенгш',
  'йцукенгшщ',
  'йцукенгшщз',
  'йцукенгшщзх',
  'фыва',
  'фывап',
  'фывапр',
  'фывапро',
  'фывапрол',
  'фывапролд',
  'фывапролдж',
  'фывапролджэ',
  'ячсм',
  'ячсми',
  'ячсмит',
  'ячсмить',
  'ячсмитьб',
  'ячсмитьбю',
  'эвм',
  'лвс',
  'user',
  'пароль',
  'привет',
  'gfhjkm' /*"пароль" в англ. раслкадке*/,
  'ghbdtn' /*"привет" в англ. раслкадке*/,
  'password',
  'hello',
];
const _notSimple: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return value.length > 0 && !_easyCombinations.includes(value);
  },
};
export const notSimple = withI18nMessage(_notSimple);

const _minLength: (min: number) => ValidationRuleWithParams<{ min: number }> = (min: number) => {
  return {
    $validator(value: string): boolean {
      return value.length >= min;
    },
    $message: ({ $params }) => `This field should be at least ${$params.min} long`,
    $params: {
      min,
      type: 'minLength',
    },
  };
};
export const minLength = withI18nMessage(_minLength, {
  withArguments: true,
});

const _fixedLength: (val: number) => ValidationRuleWithParams<{ val: number }> = (val: number) => {
  return {
    $validator(value: string): boolean {
      return value.length === val;
    },
    $message: ({ $params }) =>
      /* `This field should be at least ${$params.val} long`, */ /* t('validations.fixedLength', {n: $params.val}), */
      `Value must be ${$params.val} characters long`,
    $params: {
      val,
      type: 'fixedLength',
    },
  };
};
export const fixedLength = withI18nMessage(_fixedLength, {
  withArguments: true,
});

const _minValue: (min: number) => ValidationRuleWithParams<{ min: number }> = (min: number) => {
  return {
    $validator(value): boolean {
      return value >= min;
    },
    $message: ({ $params }) => `Value should be at least ${$params.min} `,
    $params: {
      min,
      type: 'minValue',
    },
  };
};
export const minValue = withI18nMessage(_minValue, {
  withArguments: true,
});

const _maxValue: (max: number) => ValidationRuleWithParams<{ max: number }> = (max: number) => {
  return {
    $validator(value): boolean {
      return value <= max;
    },
    $message: ({ $params }) => `Value should not exceed ${$params.max} `,
    $params: {
      max,
      type: 'maxValue',
    },
  };
};
export const maxValue = withI18nMessage(_maxValue, {
  withArguments: true,
});

const _confirm: (confirm: string) => ValidationRuleWithParams<{ confirm: string }> = (confirm: string) => {
  return {
    $validator(value: string): boolean {
      return value.length > 0 && value === confirm;
    },
    $message: () => 'The password and confirmation password do not match.',
    $params: {
      confirm,
      type: 'confirm',
    },
  };
};
export const confirm = withI18nMessage(_confirm, {
  withArguments: true,
});

const _isEmailOrPhone: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) === true) {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    } else if (/^\+?\d{11,14}$/.test(value) === true) {
      return /^\+?\d{11,14}$/.test(value);
    }
    return false;
  },
};
export const isEmailOrPhone = withI18nMessage(_isEmailOrPhone);

const _isLink: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    const linkRegex = /^https?:\/\/[-A-Za-z0-9+&@#/%?=~|!:,.;]*[-A-Za-z0-9+&@#/%=~|]/;

    return linkRegex.test(value);
  },
};
export const extentedUrl = withI18nMessage(_isLink);

/**
 * Validates that the URL is a valid link with proper domain structure.
 * @example [subdomain].[environment].[domain].[tld]/net_home
 *
 * @description The URL must:
 * - Start with http:// or https://
 * - Have a subdomain part (e.g., hamstersnet, vdsi, i)
 * - Optionally have an environment subdomain (dev, dev2, etc)
 * - Have a domain name (e.g., communex, intrakommuna)
 * - Have a top-level domain of 2+ letters (e.g., app, net)
 * - End with /net_home
 *
 * @example
 * authUrl.$validator('https://hamstersnet.dev3.communex.app/net_home'); // true
 * authUrl.$validator('https://vdsi.intrakommuna.net/net_home'); // true
 * authUrl.$validator('https://i.beta.communex.app/net_home'); // true
 * authUrl.$validator('https://example.com/net_home'); // false (missing required domain structure)
 * authUrl.$validator('https://hamstersnet.invalid-env.communex.app/net_home'); // false
 */
const _authLink: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    const linkRegex = /^https?:\/\/([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\/net_home$/;

    // const validThirdLevels = ['dev', 'dev2', 'dev3', 'dev4', 'dev5', 'dev6', 'beta'];
    // const linkRegex = new RegExp(
    //   '^https?:\\/\\/' + // Protocol (http:// or https://)
    //     '[a-zA-Z0-9-]+' + // Third-level domain part
    //     '(?:\\.(?:' +
    //     validThirdLevels.join('|') +
    //     '))?' + // Optional specific subdomain
    //     '\\.[a-zA-Z0-9-]+' + // Second-level domain
    //     '\\.[a-zA-Z]{2,}' + // Top-level domain
    //     '\\/net_home$' // Required path
    // );

    return linkRegex.test(value);
  },
};
export const authUrl = withI18nMessage(_authLink);

const _includeString: (items: string[]) => ValidationRuleWithParams<{ items: string[] }> = (items: string[]) => {
  return {
    $validator(value: string): boolean {
      return items.some((item) => value.includes(item));
    },
    $message: () => `Must contain one of the suggested values`,
    $params: {
      items,
      type: 'includeString',
    },
  };
};
export const includeString = withI18nMessage(_includeString, {
  withArguments: true,
});

/**
 * Validator for the prefix part of an email address (before the @ symbol).
 * Only allows letters, numbers, dots, underscores, and hyphens.
 *
 * @param {string} value - The input string to validate.
 * @returns {boolean} - Returns true if valid, otherwise false.
 */
const _isEmailPrefix: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    const emailPrefixRegex = /^[a-zA-Z0-9._-]+$/;
    return emailPrefixRegex.test(value);
  },
};

export const isEmailPrefix = withI18nMessage(_isEmailPrefix);

/** Use it to verify that the domain is entered correctly
 *
 * @example '@mailforspam.com' - correct, '@gmail.com' - correct
 * @example 'user@mailforspam.com'  - incorrect, '@gmail' - incorrect
 */
const _isValidDomain: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    if (typeof value !== 'string') return false;
    const emailRegex = /^@[\w.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(value);
  },
};

export const validDomain = withI18nMessage(_isValidDomain, {
  messageParams: (params) => ({ ...params, symbol: '@' }),
});
